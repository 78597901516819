import { State } from "./state";
import { GetterTree } from "vuex";

import {
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getDeletedSuccess,
  getValidationErrors,
  getIsListLoading,
  getIsRemoving,
  getIsSaving,
} from "@/store/crud/functions/getters";

export function getAttachedPlants(state: State) {
  return state.attachedPlants;
}

export function getIsAttachedPlantsLoading(state: State) {
  return state.isAttachedPlantsLoading;
}

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getDeletedSuccess,
  getValidationErrors,
  getIsListLoading,
  getIsRemoving,
  getIsSaving,
  // additional
  getAttachedPlants,
  getIsAttachedPlantsLoading,
};
