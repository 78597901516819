import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import ApiService from "@/services/ApiService";
import { AxiosResponse } from "axios";

const apiService = new ApiService();

export async function loadDashboardMessages(store: ActionContext<State, any>) {
  return new Promise((resolve, reject) => {
    apiService
      .get("/special/dashboard/user-messages", {})
      .then((response: any) => {
        if (response?.data) {
          store.commit("SET_DASHBOARD_MESSAGES", response.data);
        }
      })
      .catch((error) => {
        console.error("Dashboard Message loading error", error);
      });
  });
}

export default <ActionTree<State, any>>{
  loadDashboardMessages,
};
