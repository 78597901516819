import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MaintenanceUsers from "@/router/Maintenance/User";
import VueRouterMiddleware from "@/middlewares/VueRouterMiddleware";
import Auth from "@/router/Auth";
import auth from "@/middlewares/AuthMiddleware";
import Plant from "@/router/Plant";
import Tent from "@/router/Tent";
import User from "@/router/User";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      title: "Home",
      middleware: [auth],
      breadcrumbs: { hidden: true },
    },
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/app",
    component: () =>
      import(/* webpackChunkName: "gss-app-view" */ "../views/GSSAppView.vue"),
    children: [...Auth, ...Plant, ...Tent, ...MaintenanceUsers, ...User],
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

new VueRouterMiddleware(router);

export default router;
