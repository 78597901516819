import { IDashboardMessage, State } from "./state";
import { GetterTree } from "vuex";

export function getDashboardMessages(state: State): Array<IDashboardMessage> {
  return state.dashboardMessages;
}

export function getConfirmedDashboardMessages(
  state: State
): Array<IDashboardMessage> {
  return state.dashboardMessages.filter(
    (message: IDashboardMessage) => message.is_confirmed
  );
}

export default <GetterTree<State, any>>{
  getDashboardMessages,
  getConfirmedDashboardMessages,
};
