export default [
  {
    path: "user/profile",
    name: "UserProfile",
    component: () =>
      import(
        /* webpackChunkName: "user-profile" */ "../../views/User/Profile.vue"
      ),
    meta: {
      title: "User Profile",
      //  middleware: [NotAuthenticatedMiddleware],
    },
  },
];
