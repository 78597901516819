import { IDashboardMessage, State } from "./state";
import { MutationTree } from "vuex";

export function SET_DASHBOARD_MESSAGES(
  state: State,
  messages: Array<IDashboardMessage>
) {
  state.dashboardMessages = messages;
}

export function ADD_DASHBOARD_MESSAGES(
  state: State,
  message: IDashboardMessage
) {
  state.dashboardMessages.push(message);
}

export default <MutationTree<State>>{
  ADD_DASHBOARD_MESSAGES,
  SET_DASHBOARD_MESSAGES,
};
