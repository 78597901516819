export const Auth = {
  store: "auth",
  description_field: "id",
};

export const HomeDashboard = {
  resource: "special/dashboard/main",
};

export const IotUserVendorConfig = {
  resource: (plantId: number): string => {
    return "/plant/" + String(plantId) + "/image";
  },
  store: "plantImage",
  description_field: "created_at", // TODO change when known
};

export const LabelSheet = {
  resource: "label-sheet",
  store: null,
  description_field: "art_nr",
};

export const Plant = {
  resource: "/plant",
  store: "plant",
  description_field: "name",
};

export const PlantDetail = {
  resource: "/plant-detail",
  store: "plantDetail",
  description_field: "created_at",
};

export const PlantImage = {
  resource: (plantId: number): string => {
    return "/plant/" + String(plantId) + "/image";
  },
  store: "plantImage",
  description_field: "created_at", // TODO change when known
};

export const Tent = {
  resource: "/tent",
  store: "tent",
  description_field: "tent",
};

export const User = {
  resource: "/user",
  store: "user",
  description_field: "name",
};

export const UserProfile = {
  resource: "/user/profile",
  store: "user",
  description_field: "name",
};

export const DropdownStrains = {
  resource: "/special/dropdown/strains",
};

export const DropdownTents = {
  resource: "/special/dropdown/tents",
};
