import { CrudState } from "@/store/crud/State";
import { IPlant } from "@/interfaces/models/IPlant";

export class State extends CrudState {
  /*
    images: any[] = [];
    isImagesLoading = false;
     */
  attachedPlants: Array<IPlant> = [];
  isAttachedPlantsLoading = false;
}
