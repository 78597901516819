import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import ApiService from "@/services/ApiService";

const apiService = new ApiService();

export async function loadIotVendorConfigs(store: ActionContext<State, any>) {
  return new Promise((resolve, reject) => {
    apiService
      .get("/iot/vendor-configs", {})
      .then((response: any) => {
        console.debug("Vendor Configs", response?.data);
        if (response?.data) {
          console.debug("Vendor Configs 2", response?.data);
          store.commit("SET_IOT_VENDOR_CONFIGS", response.data);
        }
      })
      .catch((error) => {
        console.error("Vendor Configs loading error", error);
      });
  });
}

export async function loadUserIotVendorConfig(
  store: ActionContext<State, any>,
  vendorId: number
) {
  return new Promise((resolve, reject) => {
    apiService
      .get(["/iot/user-vendor-config", String(vendorId)].join("/"), {})
      .then((response: any) => {
        console.debug("User Vendor Configs", response?.data);
        if (response?.data) {
          console.debug("User Vendor Configs 2", response?.data);
          store.commit("SET_USER_IOT_VENDOR_CONFIG", {
            vendor_id: vendorId,
            config: response.data,
          });
        }
      })
      .catch((error) => {
        console.error("Vendor Configs loading error", error);
        reject(error);
      });
  });
}

export async function saveUserIotVendorConfig(options: any) {
  return new Promise((resolve, reject) => {
    apiService.put("/iot/user-vendor-config", {
      data: options.data,
    });
  });
}

export default <ActionTree<State, any>>{
  loadIotVendorConfigs,
  loadUserIotVendorConfig,
};
