import auth from "@/middlewares/AuthMiddleware";
import { Tent } from "@/config/Modules";

// tents/create
// tents
export default [
  {
    path: "tents",
    component: () =>
      import(
        /* webpackChunkName: "tents" */ "../../views/GSSApp/Tent/TentView.vue"
      ),
    meta: {
      title: "Tents",
      middleware: [auth],
    },
    children: [
      {
        path: "create",
        name: "TentCreate",
        component: () =>
          import(
            /* webpackChunkName: "tent-create" */ "../../views/GSSApp/Tent/TentCreate.vue"
          ),
        meta: {
          title: "Tent Create",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.tents)",
              name: "TentList",
            },
            { title: "trans(general.create)", name: "TentCreate" },
          ],
        },
      },
      {
        path: ":id",
        name: "TentUpdate",
        component: () =>
          import(
            /* webpackChunkName: "tent-update" */ "../../views/GSSApp/Tent/TentUpdate.vue"
          ),
        meta: {
          title: "Tent Update",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.tents)",
              name: "TentList",
            },
            {
              vuex: {
                module: Tent.store,
                getter: "getDataItem",
                column: "tent",
              },
              name: "TentShow",
            },
            { title: "trans(general.edit)", name: "TentUpdate" },
          ],
        },
      },
      {
        path: ":id/show",
        name: "TentShow",
        //redirect: { name: "TentDetailList" },
        component: () =>
          import(
            /* webpackChunkName: "tent-show" */ "../../views/GSSApp/Tent/TentShow.vue"
          ),
        meta: {
          title: "Tent Show",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.tents)",
              name: "TentList",
            },
            {
              vuex: {
                module: Tent.store,
                getter: "getDataItem",
                column: "tent",
              },
              name: "TentShow",
            },
          ],
        },
        children: [],
      },
      {
        path: "",
        name: "TentList",
        component: () =>
          import(
            /* webpackChunkName: "tent-list" */ "../../views/GSSApp/Tent/TentList.vue"
          ),
        meta: {
          title: "Tent List",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.tents)",
              name: "TentList",
            },
          ],
        },
      },
    ],
  },
];
