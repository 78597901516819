import { State } from "./state";
import { MutationTree } from "vuex";
import {
  SET_IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DELETED_SUCCESS,
  SET_VALIDATION_ERRORS,
  SET_IS_LIST_LOADING,
  SET_IS_SAVING,
  SET_IS_REMOVING,
} from "@/store/crud/functions/mutations";
import { IPlant } from "@/interfaces/models/IPlant";

export function SET_ATTACHED_PLANTS(state: State, plants: Array<IPlant>) {
  state.attachedPlants = plants;
}

export function SET_IS_ATTACHED_PLANTS_LOADING(state: State, loading = true) {
  state.isAttachedPlantsLoading = loading;
}

export default <MutationTree<State>>{
  SET_IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DELETED_SUCCESS,
  SET_VALIDATION_ERRORS,
  SET_IS_LIST_LOADING,
  SET_IS_SAVING,
  SET_IS_REMOVING,
  // additional:
  SET_ATTACHED_PLANTS,
  SET_IS_ATTACHED_PLANTS_LOADING,
};
