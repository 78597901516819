import Vue from "vue";
import Vuex from "vuex";
import Auth from "@/store/auth";
import createPersistedState from "vuex-persistedstate";
import App from "@/store/app";
import Iot from "@/store/iot";
import Table from "@/store/table";
import User from "@/store/user";
import Plant from "@/store/plant";
import PlantDetail from "@/store/plantDetail";
import Tent from "@/store/tent";
import IotUserVendorConfig from "@/store/iotUserVendorConfig";

Vue.use(Vuex);

const persistedStates = createPersistedState({
  paths: ["auth.token", "auth.accessToken", "auth.dataItem", "auth.language"],
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app: new App(),
    auth: new Auth(),
    iot: new Iot(),
    iotUserVendorConfig: new IotUserVendorConfig(),
    plant: new Plant(),
    plantDetail: new PlantDetail(),
    table: new Table(),
    tent: new Tent(),
    user: new User(),
  },
  plugins: [persistedStates],
});
