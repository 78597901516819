import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  findOne,
  findAll,
  resetSave,
  del,
  update,
  upload,
  crudActions,
} from "@/store/crud/functions/actions";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

export async function findAttachedPlantsByTentId(
  store: ActionContext<State, any>,
  options: ICrudOptions,
  force = true
) {
  if (
    force ||
    (options.force_reload ?? false) ||
    (!store.getters["getIsAttachedPlantsLoading"] &&
      store.getters["getAttachedPlants"]?.length <= 0)
  ) {
    store.commit("SET_IS_LOADING", true);
    store.commit("SET_IS_ATTACHED_PLANTS_LOADING", true);

    options.resource = `/tent/${options.id}/plants`;

    return crudActions
      .findAll(store, options)
      .then((response: any) => {
        console.debug("TentPlants", response, response.data);
        if (response.data) {
          store.commit("SET_ATTACHED_PLANTS", response.data);
        }
        return response;
      })
      .catch((error: any) => {
        // Fehlerbehandlung, z.B. Anzeige einer Fehlermeldung
        console.error("Fehler beim Laden der Bilder:", error);
        // Weiterleiten des Fehlers, damit der Aufrufer ihn behandeln kann
        throw error; // Oder eine alternative Fehlerbehandlungsstrategie
      })
      .finally(() => {
        store.commit("SET_IS_LOADING", false);
        store.commit("SET_IS_ATTACHED_PLANTS_LOADING", false);
      });
  }

  return Promise.resolve(); // Rückgabe eines leeren Promise, wenn die Bedingung nicht erfüllt ist
}

export async function detachPlantFromTent(
  store: ActionContext<State, any>,
  options: ICrudOptions
) {
  options = {
    ...options,
    resource: "/tent",
    extraPath: ["plants", options.data.id, "detach-from-tent"].join("/"),
  };

  return crudActions
    .update(store, options)
    .then((response: any) => {
      console.debug("Remove  plant from tent", response, response.data);

      return response;
    })
    .catch((error: any) => {
      console.error("Fehler beim Entfernen der Pflanze aus dem Zelt:", error);

      throw error;
    })
    .finally(() => {
      //store.commit("SET_IS_LOADING", false);
    });
}

export default <ActionTree<State, any>>{
  create,
  findOne,
  update,
  findAll,
  resetSave,
  del,
  upload,
  // additional:
  findAttachedPlantsByTentId,
  detachPlantFromTent,
};
