export const MIME_TYPES: Array<{
  mime: string;
  extension: string;
}> = [
  {
    mime: "application/pdf",
    extension: ".pdf",
  },
  {
    mime: "application/msword",
    extension: ".doc",
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    extension: ".docx",
  },
  {
    mime: "application/vnd.ms-excel",
    extension: ".xls",
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extension: ".xlsx",
  },
  {
    mime: "application/vnd.ms-powerpoint",
    extension: ".ppt",
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    extension: ".pptx",
  },
  {
    mime: "application/zip",
    extension: ".zip",
  },
  {
    mime: "application/x-gzip",
    extension: ".gz",
  },
  {
    mime: "application/json",
    extension: ".json",
  },
  {
    mime: "application/xml",
    extension: ".xml",
  },
  {
    mime: "application/xhtml+xml",
    extension: ".xhtml",
  },
  {
    mime: "application/rss+xml",
    extension: ".rss",
  },
  {
    mime: "application/octet-stream",
    extension: ".bin",
  },
  {
    mime: "application/javascript",
    extension: ".js",
  },
  {
    mime: "application/x-www-form-urlencoded",
    extension: "(usually no specific extension)",
  },
  {
    mime: "image/jpeg",
    extension: ".jpg, .jpeg",
  },
  {
    mime: "image/png",
    extension: ".png",
  },
  {
    mime: "image/gif",
    extension: ".gif",
  },
  {
    mime: "image/bmp",
    extension: ".bmp",
  },
  {
    mime: "image/webp",
    extension: ".webp",
  },
  {
    mime: "audio/mpeg",
    extension: ".mp3",
  },
  {
    mime: "audio/ogg",
    extension: ".ogg",
  },
  {
    mime: "video/mp4",
    extension: ".mp4",
  },
  {
    mime: "video/quicktime",
    extension: ".mov",
  },
  {
    mime: "text/plain",
    extension: ".txt",
  },
  {
    mime: "text/html",
    extension: ".html, .htm",
  },
  {
    mime: "text/css",
    extension: ".css",
  },
  {
    mime: "text/javascript",
    extension: ".js",
  },
  {
    mime: "text/xml",
    extension: ".xml",
  },
];

export const FEATURED_COUNTRY_CODES: Array<string> = [
  "DE",
  "FR",
  "GB",
  "US",
  "CH",
];

export const COUNTRY_CODES: Array<string> = [
  "AF",
  "AL",
  "DZ",
  "AD",
  "AO",
  "AG",
  "AR",
  "AM",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BT",
  "BO",
  "BA",
  "BW",
  "BR",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "DE",
  "KH",
  "CM",
  "CA",
  "CF",
  "TD",
  "CL",
  "CN",
  "CO",
  "KM",
  "CG",
  "CR",
  "HR",
  "CU",
  "CY",
  "CZ",
  "CD",
  "DK",
  "DJ",
  "DM",
  "DO",
  "TL",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "ET",
  "FJ",
  "FI",
  "FR",
  "GA",
  "GM",
  "GE",
  "GH",
  "GR",
  "GD",
  "GT",
  "GN",
  "GW",
  "GY",
  "HT",
  "HN",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IL",
  "IT",
  "CI",
  "JM",
  "JP",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MK",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MR",
  "MU",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NZ",
  "NI",
  "NE",
  "NG",
  "KP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PL",
  "PT",
  "QA",
  "RO",
  "RU",
  "RW",
  "KN",
  "LC",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "KR",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SZ",
  "SE",
  "CH",
  "SY",
  "TJ",
  "TZ",
  "TH",
  "TG",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UY",
  "UZ",
  "VU",
  "VA",
  "VE",
  "VN",
  "YE",
  "ZM",
  "ZW",
];

export interface IPlantDetailEventConst {
  value: string;
  title: string;
  subtitle: string;
}

export const PLANT_DETAIL_EVENTS = [
  { value: "watering", title: "modules.plant_details.events.watering.title" },
  { value: "weighing", title: "modules.plant_details.events.weighing.title" },
  {
    value: "update_growth_stage",
    title: "modules.plant_details.actions.update_growth_stage.title",
    subtitle: "modules.plant_details.actions.update_growth_stage.subtitle",
  },
  {
    value: "pruning",
    title: "modules.plant_details.actions.pruning.title",
    subtitle: "modules.plant_details.actions.pruning.subtitle",
  },
  {
    value: "repotting",
    title: "modules.plant_details.actions.repotting.title",
    subtitle: "modules.plant_details.actions.repotting.subtitle",
  },

  {
    value: "light_adjustment",
    title: "modules.plant_details.actions.light_adjustment.title",
    subtitle: "modules.plant_details.actions.light_adjustment.subtitle",
  },
  {
    value: "harvesting",
    title: "modules.plant_details.events.harvesting.title",
  },
  {
    value: "trimming",
    title: "modules.plant_details.events.trimming.title",
  },
  {
    value: "move_to_tent",
    title: "modules.plant_details.events.move_to_tent.title",
  },
];

export const GROWTH_STAGES = {
  germination: {
    label: "modules.plant_details.growth_stages.germination",
    order: 1,
    infoKey: "modules.plant_details.growth_stages.info.germination",
    fa_icon: "fas fa-seedling", // Icon für Keimung
  },
  seedling: {
    label: "modules.plant_details.growth_stages.seedling",
    order: 2,
    infoKey: "modules.plant_details.growth_stages.info.seedling",
    fa_icon: "fas fa-seedling", // Icon für Sämlinge
  },
  propagating_cutting: {
    label: "modules.plant_details.growth_stages.propagating_cutting",
    order: 3,
    infoKey: "modules.plant_details.growth_stages.info.propagating_cutting",
    fa_icon: "fas fa-hand-holding", // Icon für Stecklinge
  },
  vegetative_phase: {
    label: "modules.plant_details.growth_stages.vegetative_phase",
    order: 4,
    infoKey: "modules.plant_details.growth_stages.info.vegetative_phase",
    fa_icon: "fas fa-leaf", // Annahme: Vegetatives Wachstum
  },
  flowering: {
    label: "modules.plant_details.growth_stages.flowering",
    order: 5,
    infoKey: "modules.plant_details.growth_stages.info.flowering",
    fa_icon: "fas fa-spa", // Annahme: Blüte-Phase
  },
  flush: {
    label: "modules.plant_details.growth_stages.flush",
    order: 6,
    infoKey: "modules.plant_details.growth_stages.info.flush",
    fa_icon: "fas fa-tint", // Icon für Flush (Spülen)
  },
  harvest: {
    label: "modules.plant_details.growth_stages.harvest",
    order: 7,
    infoKey: "modules.plant_details.growth_stages.info.harvest",
    fa_icon: "fas fa-cut", // Icon für Ernte
  },
  drying: {
    label: "modules.plant_details.growth_stages.drying",
    order: 8,
    infoKey: "modules.plant_details.growth_stages.info.drying",
    fa_icon: "fas fa-wind", // Annahme: Trocknungsprozess
  },
  fermenting: {
    label: "modules.plant_details.growth_stages.fermenting",
    order: 9,
    infoKey: "modules.plant_details.growth_stages.info.fermenting",
    fa_icon: "fas fa-vial", // Annahme: Fermentierungsphase
  },
};

export const EVENT = {
  PLANT_ARCHIVED: "plant_archived",
  PLANT_DEARCHIVED: "plant_dearchived",
  IMAGE_IS_FEATURED_CHANGE: "is_featured_set_to",
};
