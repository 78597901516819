import { State } from "./state";
import { MutationTree } from "vuex";

export function SET_IOT_VENDOR_CONFIGS(state: State, iotVendorConfigs: any) {
  state.iotVendorConfigs = iotVendorConfigs;
}

export function SET_USER_IOT_VENDOR_CONFIG(
  state: State,
  iotVendorConfig: { vendor_id: number; config: any }
) {
  const temp = { ...state.userIotVendorConfigs };

  state.userIotVendorConfigs = null;

  state.userIotVendorConfigs = { ...temp };

  state.userIotVendorConfigs[String(iotVendorConfig.vendor_id)] =
    iotVendorConfig.config;
}

export default <MutationTree<State>>{
  SET_IOT_VENDOR_CONFIGS,
  SET_USER_IOT_VENDOR_CONFIG,
};
