import { State } from "./state";
import { GetterTree } from "vuex";

export function getIotVendorConfigs(state: State): any {
  console.debug("getIotVendorConfigs", state.iotVendorConfigs);
  return state.iotVendorConfigs;
}

export function getUserIotVendorConfigs(state: State): any {
  console.debug("getUserIotVendorConfigs", state.userIotVendorConfigs);
  return state.userIotVendorConfigs;
}

export function getUserIotVendorConfig(state: State): any {
  return (vendorId: number) => {
    return state.userIotVendorConfigs[String(vendorId)] || null;
  };
}

export default <GetterTree<State, any>>{
  getIotVendorConfigs,
  getUserIotVendorConfig,
  getUserIotVendorConfigs,
};
